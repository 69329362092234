* {
    box-sizing: border-box;
}

img {
  pointer-events: none;
}

input:focus {
  outline: none;
}

/* hard blink https://stackoverflow.com/questions/15469036/css3-animations-hard-blink-no-fade-inbetween-frames */
@keyframes flash {
  0% { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes spin-smooth {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*
Rotate the image with the spinner with 24 bars by 15 degrees intermittently.
Repeat for pairs of long periods of stopping and short periods of moving.
Both 0 deg and 360 deg are defined so that there are no undefined periods.
As a result, there are (number of bars)*2+1 repetitions.

@keyframes spin-15deg{
  @for $i from 0 to 49 {
    $rate: 0;
    $diff: 0;
    $angle: 0;

    @if $i % 2 == 0 {
      $rate: $i/2;
      $angle: $i/2;
      $diff: 0;
    } @else {
      $rate: ($i + 1)/2;
      $angle: ($i - 1)/2;
      $diff: 0.0001;
    }

    $angle: $angle * 15;
    $rate: $rate * 100 * 1/24 - $diff;

    #{$rate}#{'%'} {
      transform: rotate(#{$angle}deg);
    }
  }
}

Rotate the image with the spinner with 18 bars by 20 degrees intermittently.
Repeat for pairs of long periods of stopping and short periods of moving.
Both 0 deg and 360 deg are defined so that there are no undefined periods.
As a result, there are (number of bars)*2+1 repetitions.

@keyframes spin-20deg {
  @for $i from 0 to 37 {
    $rate: 0;
    $diff: 0;
    $angle: 0;

    @if $i % 2 == 0 {
      $rate: $i/2;
      $angle: $i/2;
      $diff: 0;
    } @else {
      $rate: ($i + 1)/2;
      $angle: ($i - 1)/2;
      $diff: 0.0001;
    }

    $angle: $angle * 20;
    $rate: $rate * 100 * 1/18 - $diff;

    #{$rate}#{'%'} {
      transform: rotate(#{$angle}deg);
    }
  }
}
*/

/*
Temporarily write the converted CSS to avoid SASS build error
*/
@keyframes spin-15deg{
  0%{transform:rotate(0deg)}
  4.16657%{transform:rotate(0deg)}
  4.16667%{transform:rotate(15deg)}
  8.33323%{transform:rotate(15deg)}
  8.33333%{transform:rotate(30deg)}
  12.4999%{transform:rotate(30deg)}
  12.5%{transform:rotate(45deg)}
  16.66657%{transform:rotate(45deg)}
  16.66667%{transform:rotate(60deg)}
  20.83323%{transform:rotate(60deg)}
  20.83333%{transform:rotate(75deg)}
  24.9999%{transform:rotate(75deg)}
  25%{transform:rotate(90deg)}
  29.16657%{transform:rotate(90deg)}
  29.16667%{transform:rotate(105deg)}
  33.33323%{transform:rotate(105deg)}
  33.33333%{transform:rotate(120deg)}
  37.4999%{transform:rotate(120deg)}
  37.5%{transform:rotate(135deg)}
  41.66657%{transform:rotate(135deg)}
  41.66667%{transform:rotate(150deg)}
  45.83323%{transform:rotate(150deg)}
  45.83333%{transform:rotate(165deg)}
  49.9999%{transform:rotate(165deg)}
  50%{transform:rotate(180deg)}
  54.16657%{transform:rotate(180deg)}
  54.16667%{transform:rotate(195deg)}
  58.33323%{transform:rotate(195deg)}
  58.33333%{transform:rotate(210deg)}
  62.4999%{transform:rotate(210deg)}
  62.5%{transform:rotate(225deg)}
  66.66657%{transform:rotate(225deg)}
  66.66667%{transform:rotate(240deg)}
  70.83323%{transform:rotate(240deg)}
  70.83333%{transform:rotate(255deg)}
  74.9999%{transform:rotate(255deg)}
  75%{transform:rotate(270deg)}
  79.16657%{transform:rotate(270deg)}
  79.16667%{transform:rotate(285deg)}
  83.33323%{transform:rotate(285deg)}
  83.33333%{transform:rotate(300deg)}
  87.4999%{transform:rotate(300deg)}
  87.5%{transform:rotate(315deg)}
  91.66657%{transform:rotate(315deg)}
  91.66667%{transform:rotate(330deg)}
  95.83323%{transform:rotate(330deg)}
  95.83333%{transform:rotate(345deg)}
  99.9999%{transform:rotate(345deg)}
  to{transform:rotate(1turn)}
}

@keyframes spin-20deg{
  0%{transform:rotate(0deg)}
  5.55546%{transform:rotate(0deg)}
  5.55556%{transform:rotate(20deg)}
  11.11101%{transform:rotate(20deg)}
  11.11111%{transform:rotate(40deg)}
  16.66657%{transform:rotate(40deg)}
  16.66667%{transform:rotate(60deg)}
  22.22212%{transform:rotate(60deg)}
  22.22222%{transform:rotate(80deg)}
  27.77768%{transform:rotate(80deg)}
  27.77778%{transform:rotate(100deg)}
  33.33323%{transform:rotate(100deg)}
  33.33333%{transform:rotate(120deg)}
  38.88879%{transform:rotate(120deg)}
  38.88889%{transform:rotate(140deg)}
  44.44434%{transform:rotate(140deg)}
  44.44444%{transform:rotate(160deg)}
  49.9999%{transform:rotate(160deg)}
  50%{transform:rotate(180deg)}
  55.55546%{transform:rotate(180deg)}
  55.55556%{transform:rotate(200deg)}
  61.11101%{transform:rotate(200deg)}
  61.11111%{transform:rotate(220deg)}
  66.66657%{transform:rotate(220deg)}
  66.66667%{transform:rotate(240deg)}
  72.22212%{transform:rotate(240deg)}
  72.22222%{transform:rotate(260deg)}
  77.77768%{transform:rotate(260deg)}
  77.77778%{transform:rotate(280deg)}
  83.33323%{transform:rotate(280deg)}
  83.33333%{transform:rotate(300deg)}
  88.88879%{transform:rotate(300deg)}
  88.88889%{transform:rotate(320deg)}
  94.44434%{transform:rotate(320deg)}
  94.44444%{transform:rotate(340deg)}
  99.9999%{transform:rotate(340deg)}
  to{transform:rotate(1turn)}
}
